.wallet-card {
    border-radius: 14px;
    text-align: center;
    border: 1px dashed rgba($color: #000000, $alpha: 0.2);

    &:not(:first-child) {
        margin-left: 15px;
    }

    &:not(.w-auto) {
        width: 180px;
    }

    .w-auto {
        width: 100%;
    }

    img {
        margin: 10px auto 7px;
    }

    .name {
        display: block;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        padding-bottom: 10px;
        color: rgba($secondary, 0.4);
        border-bottom: 1px solid rgba($secondary, 0.1);
    }

    a {
        display: block;
        padding: 10px;
        font-size: 14px;
    }
}
