.header {
    background-color: #fff !important;
    box-shadow: none !important;

    &.shadow {
        box-shadow: 0 4px 44px rgba(#000, 0.25) !important;
    }

    .logo {
        margin-right: 35px;
        &:hover {
            opacity: 0.7;
        }
    }

    .menu {
        display: flex;
        align-items: center;
        font-size: toRem(18);
        line-height: toRem(21);

        &-link {
            margin-left: 24px;
            font-family: $font-secondary;
            color: $secondary-light;
            font-weight: 700;
            button {
                font-family: $font-secondary;
                color: $secondary-light;
                font-weight: 700;
                font-size: 1.125rem;
                &:hover {
                    color: $secondary;
                }
            }
            &:hover {
                color: $secondary;
            }
        }
        &-link-about {
            margin-right: 32px;
        }

        &-btn {
            margin-left: 24px;
            &-arrow {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -39px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 20px solid #fff;
                }
            }
        }
        &-notif {
            margin-left: 20px;
        }

        .notif-btn {
            &.unread {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 10px;
                    height: 10px;
                    background: $brand-red;
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                }
            }
        }
    }

}

.profile-dropdown {
    .MuiMenu-paper {
        margin-top: 10px;
        width: 250px;
    }

    a,
    .logout {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
    }

    a {
        color: $secondary-light;
        padding: 3px 20px;
    }

    .logout {
        color: $primary;
        padding: 10px 20px 6px;
    }

    span {
        margin-left: 12px;
    }
}

.mobile-menu-content {
    padding: 24px;
    position: relative;
    .mobile-menu-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .mobile-logo {
        width: 100%;
        margin: 30px 0 60px;
        img {
            margin: 0 auto;
        }
    }
    .mobile-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
            &:not(:last-of-type) {
                margin-bottom: 22px;
            }
        }
    }
}