.homepage {
    background: #fff;
}

.banner {
    padding: 140px 0 70px;
    @include bg-img(
        $image: 'banner-img.svg',
        $repeat: no-repeat,
        $size: 570px 430px,
        $position: top right
    );
}

.banner-images {
    display: flex;
    align-items: flex-end;

    .left {
        width: 60%;
        margin-right: 16px;

        .img-tag {
            margin-left: auto;
        }
    }

    .right {
        width: 40%;
    }

    .img-tag {
        &.img-1 {
            width: 217px;
            max-width: 100%;
            margin-bottom: 16px;
            box-shadow: 0px 30px 60px rgba(27, 101, 120, 0.3);
        }

        &.img-2 {
            width: 370px;
            max-width: 100%;
            box-shadow: 0px 30px 60px rgba(72, 90, 253, 0.3);
        }

        &.img-3 {
            width: 145px;
            max-width: 100%;
            margin-bottom: 16px;
        }

        &.img-4 {
            width: 290px;
            max-width: 100%;
            box-shadow: 0px 34px 50px rgba(235, 122, 125, 0.4);
        }
    }
}

.home-products {
    position: relative;
    z-index: 1;
    .home-product {
        width: 240px;
        height: 225px;

        > div {
            height: 100%;
        }

        a {
            display: block;
            height: 100%;
        }
    }
}

.home-about {
    margin-top: -155px;
    padding: 180px 0 200px;
    position: relative;
    z-index: 0;
    @include bg-img(
        $image: 'bg-gray-to-right.svg',
        $repeat: no-repeat,
        $size: 100% 100%,
        $position: center
    );

    &:after {
        content: '';
        z-index: -1;
        max-width: 28%;
        max-height: 61%;
        width: 495px;
        height: 384px;
        @include position($bottom: -50px, $left: 0);
        @include bg-img(
            $image: 'pattern-left.svg',
            $repeat: no-repeat,
            $size: 100% 100%,
            $position: right top
        );
    }

    .about-text {
        font-size: 1.25rem;
        font-family: $font-primary;
        font-weight: normal;
        line-height: 2rem;
        color: #1D1F3B;
    }
}

.home-top-bids {
    padding-bottom: 100px;
}

.home-featured-artists {
    padding: 85px 0 180px;
    position: relative;
    z-index: 0;
    @include bg-img(
        $image: 'bg-gray-to-left.svg',
        $repeat: no-repeat,
        $size: 100% 100%,
        $position: center
    );
    .description {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: $font-primary;
        font-weight: 400;
    }
    &:after {
        content: '';
        z-index: -1;
        max-width: 31%;
        max-height: 67%;
        width: 500px;
        height: 506px;
        @include position($top: 55%, $right: 0);
        transform: translateY(-50%);
        @include bg-img(
            $image: 'pattern-right.svg',
            $repeat: no-repeat,
            $size: 100% 100%,
            $position: right top
        );
    }
}

.home-best-voted {
    padding: 130px 0 200px;
}
