@media (max-width: $screen-md) {
  .header {
    .menu {
      &-link {
        margin-left: 12px;
      }
      &-btn {
        margin-left: 12px;
        .MuiButtonBase-root {
          &.MuiButton-sizeLarge {
            min-width: 115px;
          }
        }
      }
      &-notif {
        margin-left: 12px;
      }
    }
    .MuiButton-outlinedSizeLarge {
      padding: 13px;
    }
    .MuiButton-containedSizeLarge {
      padding: 13px;
    }
  }
  .tabs-header {
    .MuiTab-root {
      min-width: 125px;
    }
  }
}
@media (max-width: $screen-sm) {
  .header {
    .menu {
      &-link-about {
        display: none;
      }
      &-btn-marketplace {
        margin-left: 0;
      }
      &-btn {
        a,
        button {
          font-size: 0.85rem !important;
        }
      }
    }
  }
  .tab-search {
    &-btns {
      button {
        width: 100px;
      }
    }
  }
  .footer {
    background-size: 285px 215px;
  }
}
@media (max-width: $screen-xs) {
  .breadcrumbs {
    a, p {
      font-size: 12px;
    }
  }
  .header {
    .menu-btn {
      &-marketplace,
      &-algo,
      &-wallet {
        display: none;
      }
    }
  }
  .mobile-menu-content {
    .mobile-menu {
      .menu-btn {
        width: 100%;
        button {
          width: 100%;
        }
        img {
          margin: 0 auto;
        }
      }
    }
  }
  .sort-dropdown {
    .filter-checkboxes {
      display: block;
      min-width: 220px;
      &-column {
        &:not(:last-of-type) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
      .checklist-title {
        margin-bottom: 10px;
      }
    }
  }
  .dropdown-prices {
    img {
      width: 10px;
    }
  }
  .MuiButtonBase-root.MuiButton-sizeLarge {
    min-width: 120px;
  }
  .footer {
    background-size: 180px 135px;
    .footer-nav-item {
      width: 50%;
      margin-bottom: 20px !important;
    }
    .footer-bottom-content {
      display: block;
      & > div {
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: $screen-xxs) {
  .tab-search {
    display: block;
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    &-btns {
      margin-top: 10px !important;
      button {
        width: 50%;
        height: 40px;
        &:first-child {
          border-left: 1px solid rgba($secondary, 0.23);
          border-radius: $br 0 0 $br;
        }
      }
    }
  }
  .dropdown-btn-people {
    margin-right: 10px;
    svg {
      margin-left: 5px;
    }
  }
  .tabs-header {
    .MuiTab-root {
      min-width: 100px;
    }
  }
}