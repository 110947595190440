.card-disabled {
    opacity: 0.6;
    pointer-events: none;
}

.card-series {
    position: relative;

    &:after,
    &:before {
        content: '';
        @include center(horizontal);
        z-index: -1;
        height: 100%;
        background: #fff;
        border-radius: $br;
        box-shadow: 0 24px 60px rgba($secondary, 0.08);
    }

    &:after {
        width: calc(100% - 8px);
        bottom: -8px;
    }

    &:before {
        width: calc(100% - 16px);
        bottom: -16px;
    }
}

.card-img-wrap {
    position: relative;
    overflow: hidden;

    .card-top-action {
        z-index: 1;
        @include position($top: 8px, $right: 8px);
        display: flex;
        align-items: center;
        justify-content: center;

        label {
            margin: 0;
        }
    }

    .card-img {
        position: relative;
        overflow: hidden;
        .card-image {
            display: block;
            border-radius: $br $br 0 0;
            margin: 0 auto;
        }
    }
}

.card-dropdown {
    margin-top: 8px;

    a,
    button{
        width: 100%;
        padding: 5px 30px;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        align-items: center;

        span {
            margin-left: 16px;
        }
    }
}

.featured-artist-img-wrap {
    position: relative;
    margin-bottom: 38px;

    .featured-artist-img {
        @include position($bottom: -30px, $left: 20px);
        padding: 8px;
        background: #fff;
    }
    .featured-artist-tags {
        position: absolute;
        right: 20px;
        top: 20px;
        .tag {
            &:not(:last-of-type) {
                margin-right: 0.5rem;
            }
        }
    }
}

.MuiCard-root {
    overflow: visible !important;
}
.followers-info {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $secondary-light;
    span {
        color: $secondary;
    }
}
.people-min-card {
    // width: 20%;
    margin: 12px 0;
    .min-avatar {
        margin-right: 20px;
        .MuiAvatar-circle {
            width: 56px;
            height: 56px;
        }
    }
    .tag-body {
        margin-top: 0.25rem;
    }
    .tag {
        position: static;
    }
}

.card-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 32px;
  position: relative;
  border-radius: $br;
  overflow: hidden;
  .image-list {
    display: flex;
    & > li {
      border-radius: $br;
      overflow: hidden;
      margin-right: 12px;
      img {
        width: 100px;
        height: 80px;
      }
    }
  }
  .image-count {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    color: $secondary;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
  }
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 280px;
    background: linear-gradient(270deg, #FFFFFF 42.02%, rgba(255, 255, 255, 0) 100%);
    right: 0;
    top: 0;
  }
}
.card-bio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px 32px;
    position: relative;
    overflow: hidden;

  }
.card-img {
    position: relative;
    .card-image-open {
        display: none;
        position: absolute;
        bottom: 10px;
        left: 10px;
        button {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            svg {
                transform: rotate(45deg);
            }
        }
    }
    &:hover {
        .card-image-open {
            display: block;
        }
    }
}
.bid-row {
    .bid-price {
        font-family: $font-primary;
    }
    .voted {
        font-family: $font-primary;
    }
}

.post-auction-card {
    position: relative;
    height: 190px;
    .card-img-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .card-top-action {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 2;
    }
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover !important;
    position: relative;
    & > .tag.bottom-right {
        bottom: 12px;
    }
    &-header {
        overflow: hidden;
        position: relative;
        transform: translateY(-100%);
        transition: all .3s ease;
        &-actions {
            z-index: 1;
            @include position($top: 8px, $right: 8px);
            .card-top-action {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: .5rem;
                label {
                    margin: 0;
                }
            }
        }
        .tag.bottom-right {
            bottom: -18px;
        }
    }
    &-footer {
        position: relative;
        /*visibility: hidden;
        opacity: 0;*/
        background: rgba(255, 255, 255, 0.9);
        border-radius: 0 0 $br $br;
        transform: translateY(100%);
        transition: all .3s ease;
        .tag.top-right {
            right: 0;
            top: 0;
        }
    }
    &:hover {
        & > .tag.bottom-right {
            display: none;
        }
        .card-header,
        .card-footer {
            transform: translateY(0);
            .tag.top-right {
                top: -18px;
            }
        }
    }
}

.openCardMenu {
    & > .tag.bottom-right {
        display: none;
    }
    .card-header,
    .card-footer {
        transform: translateY(0);
        .tag.top-right {
            top: -18px;
        }
    }
}

.card-image-open {
    button {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        svg {
            transform: rotate(45deg);
        }
    }
}

.asset-card {
    overflow: hidden !important;
    .asset-file-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        img,
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.card-lottie {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}