@media (max-width: 1699px) {
  .auction {
    &-container {
      &.collapsed {
        .card-item {
          max-width: 50%;
          flex-basis: 50%;
        }
      }
    }
  }
}
@media (max-width: 1480px) {
  .auction {
    .auctions-activity {
      width: 320px;
      &-title {
        padding: 24px;
      }
      &-list {
        & > li {
          padding: 12px 24px;
          .image {
            width: 46px;
          }
          .info {
            width: calc(100% - 62px);
            .asset-image {
              width: 46px;
              height: 46px;
            }
          }
        }
      }
      &-navigate {
        width: 20px;
        height: 46px;
        top: 62px;
        right: -20px;
        padding: 0;
      }
    }
    &-container {
      &.collapsed {
        padding-left: 360px;
      }
    }
  }
}
@media (max-width: $screen-sm) {
  .auction {
    &-container {
      &.collapsed {
        padding-left: 24px;
      }
    }
    .filter-list {
      margin: 0 -24px;
      width: calc(100% + 48px);
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        width: 35px;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
    .button-tag-list {
      width: 100%;
      overflow-y: hidden;
      padding: 0 24px;
      & > div {
        flex-flow: row nowrap;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
      .button-tag {
        margin-bottom: 0;
        .text {
          white-space: nowrap;
        }
      }
    }
    .filter-sort {
      width: calc(100% - 48px);
      justify-content: flex-start;
      padding: 20px 0;
      margin: 0 24px;
      .dropdown-btn {
        margin-left: 0 !important;
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }
  }
}
@media (max-width: $screen-xs) {
  .auction {
    .auctions-activity {
      width: 100%;
      &-navigate {
        width: 26px;
        height: 32px;
        top: 59px;
        right: -26px;
      }
      &.open {
        .auctions-activity-navigate {
          width: 32px;
          height: 48px;
          top: 38px;
          right: 0;
          transform: rotate(180deg);
        }
      }
    }
    &-container {
      &.collapsed {
        padding-left: 16px;
      }
    }
    &-title  {
      h2 {
        font-size: 2rem;
        margin-left: 16px;
      }
      .post-auction-btn {
        min-width: 120px;
      }
    }
    .filter-list {
      margin: 0 -16px;
      width: calc(100% + 32px);
    }
    .button-tag-list {
      padding: 0 16px;
    }
    .filter-sort {
      margin: 0 16px;
    }
  }
}