@media (max-width: $screen-xs) {
  .activity {
    &-list {
      li {
        display: block;
        .time {
          margin-top: 8px;
        }
      }
    }
  }
}