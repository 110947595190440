.upload-assets {
    width: 100%;
    max-width: 760px;
    margin: 50px auto;

    .stepper {
        padding: 25px 0;
        background-color: transparent;

        .step-label {
            span {
                font-weight: 700;
            }
        }
    }

    .inner-padd {
        padding: 0 50px;
    }

    .uploaded-image {
        max-width: 100%;
        border-radius: $br;

        &.active {
            border: 4px solid $primary;
        }
    }
    &-button {
        input[type="file"] {
            color: transparent;
            &::-webkit-file-upload-button {
                visibility: hidden;
            }
            &::before {
                content: 'Upload';
                display: inline-block;
                outline: none;
                -webkit-user-select: none;
                cursor: pointer;
                background: $primary;
                border: 1px solid rgba($secondary, 0.3);
                box-sizing: border-box;
                border-radius: $br;
                font-weight: bold;
                font-size: 1.125rem;
                text-align: center;
                color: #FFFFFF;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                line-height: 48px;
                transition: all .3s ease;
            }
            &:active {
                outline: 0;
            }
            &:hover::before {
                background: #3a48cb;
            }
        }
    }
}

.upload-card {
    border-radius: $br;
    background-color: #fff;
    border: 1px dashed $primary;
}

.asset-preview {
    .preview-meta {
        padding: 16px 45px;
        box-shadow: 0 4px 44px rgba($secondary, 0.25);
    }
    .radio-group {
        flex-direction: row;
        label {
            display: inline-block;
            margin-right: 30px;
            span {
                font-size: 1rem;
                font-family: $font-primary;
                font-weight: 400;
                &.MuiFormControlLabel-label {
                    color: $secondary-light;
                }
                &.Mui-checked + .MuiFormControlLabel-label {
                    color: $primary;
                }
            }
        }
    }
    &-image {
        audio {
            width: 100%;
        }
        iframe {
            height: 420px;
            width: 100%;
        }
        video {
            width: 100%;
            height: 100%;
        }
    }
    .preview-slider {
        width: calc(100% - 493px);
        padding: 23px 50px;
        .slick-slide {
            border-radius: $br;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
    }

    .preview-description {
        width: 493px;
        padding: 35px 30px;
        border-left: 1px solid rgba($secondary, 0.1);

        .preview-tabs {
            margin-top: 25px;

            .MuiTab-root {
                width: 50%;

            }
        }
    }
}