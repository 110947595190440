.marketplace-auction {
  background-color: $secondary;
  padding: 36px 0 40px;
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    z-index: -1;
    max-width: 28%;
    max-height: 61%;
    width: 546px;
    height: 384px;
    opacity: .5;
    mix-blend-mode: luminosity;
    @include position($bottom: 0, $left: -50px);
    @include bg-img(
            $image: 'marketplace-pattern-left.svg',
            $repeat: no-repeat,
            $size: 100% 100%,
            $position: right top
    );
  }
  &:before {
    content: '';
    z-index: -1;
    max-width: 28%;
    max-height: 61%;
    width: 546px;
    height: 384px;
    opacity: .5;
    mix-blend-mode: luminosity;
    @include position($top: 0, $right: -80px);
    @include bg-img(
            $image: 'marketplace-pattern-right.svg',
            $repeat: no-repeat,
            $size: 100% 100%,
            $position: right top
    );
  }
  .marketplace-auction-title {
    h2 {
      color: #fff;
      text-transform: none;
      span {
        cursor: pointer;
      }
    }
  }
  .see-more-btn {
    button {
      color: #fff;
      border-color: #fff;
      &:hover {
        background-color: rgba(255,255,255,.2);
        border-color: #fff;
      }
    }
  }
}
.marketplace-content {
  padding: 32px 0 40px;
  .button-tag-list {
    width: calc(100% - 260px);
  }
  .filter-sort {
    display: flex;
    justify-content: flex-end;
    width: 260px;
  }
  .people-min-card {
    width: 20%;
  }
  .marketplace-result {
    h3 {
      text-transform: capitalize;
      font-weight: 700;
    }
    .no-results-found {
      margin: 60px 12px;
      font-size: 32px;
      line-height: 48px;
    }
  }
}