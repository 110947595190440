.breadcrumbs {
  padding: 16px 0 22px;
  a {
    font-size: 14px;
    font-weight: bold;
    color: $primary;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    color: $secondary-light;
  }
}