@media (max-width: $screen-md) {
  .people-min-card {
    .min-avatar {
      margin-right: 12px;
    }
  }
  .marketplace-content {
    .people-min-card {
      width: 25%;
    }
  }
  .card-footer {
    padding: 16px 16px 8px !important;
  }
  .bid-row {
    .num-of-bids {
      font-size: 12px;
    }
    .bid-price {
      font-size: 16px;
    }
    .voted {
      svg {
        font-size: 18px !important;
      }
    }
  }
}
@media (max-width: $screen-sm) {
  .marketplace-content {
    .people-min-card {
      width: 33.3%;
    }
  }
  .card-images {
    margin: 0 16px 32px;
  }
  .card {
    &-header,
    &-footer {
      transform: translateY(0);
    }
  }
}
@media (max-width: $screen-xs) {
  .marketplace-content {
    .people-min-card {
      width: 50%;
    }
  }
}
@media (max-width: $screen-xxs) {
  .people-min-card {
    .min-avatar {
      margin-right: 8px;
      .MuiAvatar-circle {
        width: 38px;
        height: 38px;
      }
    }
  }
  .card-images {
    .image-list {
      & > li {
        margin-right: 5px;
        img {
          width: 70px;
          height: 60px;
        }
      }
    }
    &:before {
      width: 70%;
    }
  }
}