.list-with-link {
    position: relative;

    .list-link {
        @include center(vertical);
        right: -10px;
        z-index: 10;
        background: $primary;
        color: #fff;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: $primary-dark;
        }
    }
}
