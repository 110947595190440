html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    word-break: break-word;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

html {
    -webkit-tap-highlight-color: rgba(#000, 0);
    -webkit-tap-highlight-color: transparent;
}

body {
    line-height: 1;
}

input:focus,
input:active,
button:focus,
button:active,
textarea:focus,
textarea:active,
select:focus,
select:active,
a:focus,
a:active {
    outline: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner,
select::-moz-focus-inner,
a::-moz-focus-inner {
    border: 0;
    -webkit-tap-highlight-color: rgba(#000, 0);
    -webkit-tap-highlight-color: transparent;
}

*,
*:before,
*:after {
    outline: none;
    box-sizing: border-box;
}

ul,
li {
    list-style: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

img {
    display: block;
}

a {
    text-decoration: none;
    display: inline-block;
    color: inherit;
}

i {
    display: inline-block;
}

button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    &::-moz-focus-inner {
        border: 0;
    }
}

textarea {
    resize: none;
    overflow: auto;
}

a,
button,
input[type='submit'] {
    transition: 0.3s ease;
    cursor: pointer;
}

input,
a,
textarea,
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    font-family: $font-primary;
}

input[type='search'] {
    appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-text-fill-color: #000 !important;
}

@-webkit-keyframes autofill {
    to {
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

input:required,
input:invalid {
    box-shadow: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

pre {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
    font-family: $font-primary;
}

sup {
    vertical-align: super;
}
