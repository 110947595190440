.activity {
  &-list {
    li {
      padding: 12px 0;
      border-bottom: 1px solid rgba($secondary, 0.1);
      .image {
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:first-child {
          margin-left: 0;
        }
      }
      p {
        font-family: $font-primary;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $secondary;
      }
      a {
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0 .3125rem;
      }
      .time {
        font-family: $font-primary;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $secondary-light;
      }
    }
  }
}