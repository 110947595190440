$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: $icomoon-folder !default;

$icon-notification: "\e91b";
$icon-upload: "\e917";
$icon-label: "\e91a";
$icon-logout: "\e90f";
$icon-user: "\e90e";
$icon-file-upload: "\e90d";
$icon-fire: "\e90c";
$icon-discord: "\e90b";
$icon-virtual-reality: "\e900";
$icon-animation: "\e901";
$icon-video: "\e902";
$icon-audio: "\e904";
$icon-graphic: "\e905";
$icon-twitter: "\e906";
$icon-facebook: "\e907";
$icon-instagram: "\e908";
$icon-youtube: "\e909";
$icon-reddit: "\e90a";
$icon-calendar-end: "\e910";
$icon-calendar-start: "\e911";
$icon-post-icon3: "\e914";
$icon-post-icon1: "\e912";
$icon-post-icon2: "\e913";
$icon-post-icon4: "\e915";
$icon-notifications-outline: "\e918";
$icon-information-outline: "\e916";
$icon-exclamation-triangle: "\e903";
$icon-share-2: "\e919";
