// Paths
$images-folder: '/assets/img/';
$icomoon-folder: '/assets/fonts/icomoon/';

// Fonts
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Raleway', sans-serif;
$font-icomoon: 'icomoon';

// Main colors
$primary: #485afd;
$primary-dark: #3a48cb;
$primary-light: rgba($primary, 0.07);
$secondary: #000;
$error: #ec3136;
$secondary-light: rgba($secondary, 0.5);

// Brand colors
$brand-orange: #f7ae75;
$brand-red: #eb7a7d;
$brand-gold: #f4d45d;
$brand-yellow: #fbf6a8;
$brand-green: #3eb595;
$brand-blue: #1b6578;

// Notification colors
$notif-success: #3EB595;
$notif-error: #E73035;
$notif-info: $primary;
$notif-warning: #F1AA72;

$notif-colors: (
    'success': $notif-success,
    'error': $notif-error,
    'info': $notif-info,
    'warning': $notif-warning
);

$colors: (
    'primary': $primary,
    'secondary': $secondary,
    'brand-orange': $brand-orange,
    'brand-red': $brand-red,
    'brand-gold': $brand-gold,
    'brand-yellow': $brand-yellow,
    'brand-green': $brand-green,
    'brand-blue': $brand-blue,
    'white': #fff,
);

// Backgrounds
$bg-grey: #efefef;

// Border radius
$br-sm: 6px;
$br: 10px;

// Font weights
$font-weights: (
    '400': 400,
    '500': 500,
    '600': 600,
    '700': 700,
    '800': 800,
    '900': 900,
);


// screen width

$screen-xxs: 459px;
$screen-xs: 599px;
$screen-ssm: 767px;
$screen-sm: 959px;
$screen-md: 1279px;
$screen-lg: 1919px;