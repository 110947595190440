@media (max-width: $screen-md) {
  .single-asset {
    .asset {
      &-left {
        padding: 24px 24px 0 0;
        width: calc(100% - 360px);
      }
      &-right {
        width: 360px;
        &-top {
          padding: 30px 16px;
        }
        &-center {
          padding: 22px 16px;
        }
        &-bottom {
          padding: 20px 16px 30px;
        }
        .creator-collector {
          .people-min-card {
            .min-avatar {
              margin-right: 8px;
              .MuiAvatar-circle {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
  .edit-asset {
    &-left {
      width: 480px;
      margin-right: 40px;
      // .image {
      //   & > div {
      //     height: 420px !important;
      //   }
      // }
    }
    &-right {
      width: calc(100% - 520px);
    }
  }
  .asset-mini-content {
    &-body {
      display: block !important;
      .left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }
      .right {
        width: 100%;
      }
    }
  }
}
@media (max-width: $screen-sm) {
  .single-asset {
    &-container {
      padding: 0 !important;
    }
    .asset {
      display: block;
      &-left {
        width: 100%;
        padding: 0;
        .escrow-buttons {
          padding: 32px 16px;
        }
        .image {
          border-radius: 0;
        }
        &-bottom {
          padding: 0 16px;
        }
      }
      &-right {
        width: 100%;
        border: none;
        &-top {
          padding: 30px 16px;
        }
        &-center {
          padding: 22px 16px;
        }
        &-bottom {
          padding: 20px 16px 30px;
        }
        .creator-collector {
          .people-min-card {
            .min-avatar {
              margin-right: 8px;
              .MuiAvatar-circle {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }
  .edit-asset {
    &-body {
      display: block !important;
    }
    &-left {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      // .image {
      //   & > div {
      //     height: auto !important;
      //     min-height: 420px;
      //   }
      // }
    }
    &-right {
      width: 100%;
    }
  }
}
@media (max-width: $screen-xs) {
  .single-asset {
    .asset {
      &-left {
        &-bottom {
          display: block;
          .report-btn {
            margin-top: 16px;
          }
        }
      }
      &-right {
        .tag {
          top: -38px;
        }
      }
    }
  }
}