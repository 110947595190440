@media (max-width: $screen-sm) {
  .marketplace-content {
    .filter-list {
      margin: 0 -24px;
      width: calc(100% + 48px);
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        width: 35px;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
    .button-tag-list {
      width: 100%;
      overflow-y: hidden;
      padding: 0 24px;
      & > div {
        flex-flow: row nowrap;
      }
      /* width */
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
      .button-tag {
        margin-bottom: 0;
        .text {
          white-space: nowrap;
        }
      }
    }
    .filter-sort {
      width: calc(100% - 48px);
      justify-content: flex-start;
      padding: 20px 0;
      margin: 0 24px;
      .dropdown-btn {
        margin-left: 0 !important;
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }
  }
}
@media (max-width: $screen-xs) {
  .marketplace-content {
    .filter-list {
      margin: 0 -16px;
      width: calc(100% + 32px);
    }
    .button-tag-list {
      padding: 0 16px;
    }
    .filter-sort {
      margin: 0 16px;
    }
  }
}