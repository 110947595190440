.profile-banner {
    height: 272px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .file-upload {
        width: 100%;
        height: 100%;
        background: rgba($secondary, 0.3);
        transition: 0.3s;

        &:hover {
            background-color: rgba($secondary, 0.4);
        }

        &.uploaded {
            background-color: transparent;

            label {
                opacity: 0;
                transition: 0.3s;
            }

            &:hover {
                background-color: rgba($secondary, 0.3);

                label {
                    opacity: 1;
                }
            }
        }
    }
}

.profile-content {
    display: flex;
    margin: -24px 0 40px;
    position: relative;
    z-index: 1;
}

.profile-sidebar {
    width: 355px;
    margin-top: -80px;
    padding-right: 25px;
    &-tags {
        .tag {
            &:not(:last-of-type) {
                margin-right: 0.5rem;
            }
        }
    }
    .sidebar-menu {
        li {
            padding: 0;
        }

        a,
        button {
            padding: 15px 15px 15px 30px;
            width: 100%;
            min-height: 55px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $secondary-light;
            font-weight: 700;
            font-family: $font-secondary;

            &.active,
            &:hover {
                color: $primary;
                background-color: $primary-light;

                > span {
                    color: $primary;
                }
            }

            &.settings-link {
                color: $primary;

                p {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 12px;
                    }
                }

                .settings-warning {
                    display: flex;
                    align-items: center;
                    min-width: 50px;
                    justify-content: center;
                }
            }

            > span {
                color: $secondary;
                min-width: 50px;
                text-align: center;
            }
        }
    }
}

.profile-tabs {
    width: calc(100% - 355px);
}

.profile-top-mobile {
    margin-top: -100px;
    &-tags {
        .tag {
            &:not(:last-of-type) {
                margin-right: 0.5rem;
            }
        }
    }
}
.profile-mobile-menu {
    position: relative;
    .mobile-menu-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .profile-sidebar {
        width: 100%;
        margin: 0;
        padding: 0;
        & > div {
            border-radius: 0;
            border: none;
        }
    }
}

.avatar-container{
    position: relative;

    .avatar-upload-icon{
        position:absolute;
        top: 0;
        left: 45px;
        opacity: 0;
        transition: 0.3s !important;
    }
    
    &:hover{
        opacity: 0.7;
        cursor: pointer;
        .avatar-upload-icon{
            opacity: 1;
        }
    }
}