.publish-asset-modal {
  .modal-content {
    .modal-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #FFFFFF;
      box-shadow: 0 4px 44px rgba($secondary, 0.25);
      border-radius: $br $br 0 0;
      padding: 16px 45px 16px 36px;
      button {
        font-weight: bold;
        font-size: 1rem;
        &.cancel-btn {
          color: $error;
        }
        &.continue-editing-btn {
          color: $primary;
          margin-right: 22px;
        }
        &.publish-btn {
          font-size: 1.125rem;
          min-width: 138px;
          padding: 13px;
        }
      }
    }
    .modal-body {
      display: flex;
      .left {
        width: calc(100% - 500px);
        padding: 24px 24px 24px 36px;
        .image {
          position: relative;
          border-radius: $br;
          overflow: hidden;
        }
        .report-btn {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 1rem;
          color: $secondary-light;
          i {
            font-size: 1.25rem;
            margin-right: 14px;
          }
        }
      }
      .right {
        width: 500px;
        border-left: 1px solid rgba($secondary, 0.1);
        &-top {
          padding: 30px;
          h4 {
            margin-bottom: 20px;
          }
          .creator {
            h6 {
              font-size: 1rem;
              font-weight: bold;
              color: $secondary-light;
            }
          }
          .like-fav {
            span {
              font-family: $font-primary;
              font-weight: bold;
              color: rgba($secondary, 0.55);
            }
            svg {
              color: rgba($secondary, 0.55);
            }
          }
        }
        &-center {
          padding: 22px 30px;
          background: #FFFFFF;
          box-shadow: 0 24px 60px rgba(38, 43, 63, 0.15);
          position: relative;
          margin-top: 25px;
          .tag {
            border-radius: $br $br 0 0;
            padding: 13px 40px;
            top: -42px;
            right: 30px;
          }
          p {
            font-family: $font-primary;
            font-weight: bold;
            color: $secondary-light;
            margin-right: 12px;
          }
          .start-bid {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 2rem;
            line-height: 2.3rem;
            color: $secondary;
          }
        }
        &-bottom {
          padding: 20px 30px 40px;
          & > p {
            font-family: $font-primary;
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $secondary;
            margin-bottom: 26px;
          }
          .preview-tabs {
            button {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
.bid-modal {
  .modal-body {
    display: flex;
    .left {
      width: calc(100% - 420px);
      padding: 36px;
      border: 1px solid rgba($secondary, 0.1);
      h4 {
        margin-bottom: 30px;
      }
      .image {
        position: relative;
        border-radius: $br;
        overflow: hidden;
      }
    }
    .right {
      width: 420px;
      padding: 90px 24px 24px;
      position: relative;
      .time {
        position: absolute;
        width: 100%;
        top: 38px;
        left: 0;
      }
      .last-bid {
        margin-right: 38px;
        p {
          font-family: $font-primary;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1rem;
          color: $secondary-light;
          margin-bottom: 5px;
        }
        span {
          font-family: $font-primary;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $secondary;
        }
      }
      .people-min-card {
        margin: 0;
      }
      .bid-info {
        li {
          padding: 16px 0;
          span {
            font-family: $font-primary;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1rem;
            color: $secondary-light;
          }
          b {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $secondary;
          }
          &:not(:last-of-type) {
            border-bottom: 1px dashed rgba($secondary, 0.1);
          }
        }
      }
      .your-offer {
        p {
          font-family: $font-primary;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1rem;
          color: $secondary-light;
        }
      }
      .bid-total {
        span {
          font-family: $font-primary;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1rem;
          color: $secondary-light;
        }
        b {
          font-family: $font-primary;
          font-weight: bold;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $secondary;
        }
      }
      .cancel-btn {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $secondary-light;
        margin-right: 46px;
      }
    }
  }
}
.bid-modal-done {
  .modal-body {
    padding: 40px 120px;
    text-align: center;
    h4 {
      margin-bottom: 40px;
    }
    .bid-image {
      max-width: 150px;
      width: 100%;
      margin: 0 auto 20px;
      position: relative;
      .image {
        border-radius: $br;
        overflow: hidden;
      }
      .icon {
        position: absolute;
        top: -20px;
        right: -20px;
      }
    }
    h6 {
      margin-bottom: 24px;
      font-family: $font-primary;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary-light;
    }
  }
}

.cancel-popup {
  .MuiDialog-container {
    .MuiDialog-paperWidthSm {
      max-width: 360px;
    }
  }
  &-content {
    padding: 32px;
    .title {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $secondary;
      margin-bottom: 20px;
    }
    .desc {
      font-weight: normal;
      font-size: 1.125rem;
      color: rgba($secondary, 0.7);
    }
    a {
      font-size: 1rem;
    }
  }
}
.report-popup {
  .MuiDialog-container {
    .MuiDialog-paperWidthSm {
      max-width: 560px;
    }
  }
  &-content {
    padding: 32px;
    .title {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: $secondary;
      margin-bottom: 20px;
    }
    a {
      font-size: 1rem;
    }
  }
  &-body {
    .MuiTypography-body1 {
      font-family: $font-primary;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.25rem;
      color: rgba($secondary, 0.5);
    }
  }
}

.mini-modal {
  .modal-body {
    padding: 40px;
    text-align: center;
    h4 {
      margin-bottom: 40px;
    }
    .mini-modal-image {
      max-width: 150px;
      width: 100%;
      margin: 0 auto 20px;
      position: relative;
      .image {
        border-radius: $br;
        overflow: hidden;
        img {
          margin: 0 auto;
        }
      }
    }
    h6 {
      margin-bottom: 24px;
      font-family: $font-primary;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $secondary-light;
      a {
        color: $primary;
      }
    }
  }
}

.image-modal {
  background-color: $secondary;
  &-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100vh;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
  }
  &-img {
    width: 100%;
    height: auto;
    max-width: 90%;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0;
    margin: 0 auto;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .image-close-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    background: rgba(0,0,0,0.4);
    color: #fff;
    cursor: pointer;
  }
}
.change-wallet-modal {
  .MuiDialog-container .MuiDialog-paperWidthXs {
    max-width: 360px;
  }
  &-head {
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba($secondary, 0.1);
    .title {
      font-weight: bold;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $secondary;
    }
    img {
      display: block;
      width: 100px;
    }
  }
  &-body {
    padding: 1.5rem 2rem 2rem;
    .radio-label {
      .MuiTypography-body1 {
        font-weight: bold;
        font-size: 0.9375rem;
        line-height: 1rem;
        color: $secondary;
      }
    }
  }
}

.wallet-modal {
  &-item {
    &-title {
      width: 160px;
      text-align: left;
    }
    &-result {
      width: calc(100% - 160px);
      text-align: left;
      &.wallet-address {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}