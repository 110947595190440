.post-auction {
  padding: 54px 0 135px;
  position: relative;
  h4 {
    text-align: center;
    margin-bottom: 26px;
  }
  .post-auction-steps {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    max-width: 820px;
    margin: 0 auto 20px;
    .step {
      .MuiTypography-displayBlock {
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $primary;
        margin-top: 8px;
      }
      .MuiStepConnector-lineHorizontal {
        border-top-width: 2px;
      }
      .Mui-disabled {
        .MuiStepConnector-line {
          border-color: rgba($secondary, 0.1);
        }
        .MuiTypography-displayBlock {
          color: $secondary-light;
        }
        .MuiStepIcon-root {
          color: rgba($secondary, 0.1);
        }
        .MuiStepIcon-text {
          color: rgba($secondary, 0.3);
          fill: rgba($secondary, 0.3);
        }
      }
      .MuiStepConnector-line {
        border-color: $primary;
      }
    }
  }
  .steps {
    .second-step {
      max-width: 700px;
      padding-top: 20px;
      margin: 0 auto;
      &-fields {
        & > div {
          width: calc(50% - 23px);
          margin-right: 46px;
          margin-bottom: 50px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .third-step {
      max-width: 320px;
      padding-top: 55px;
      margin: 0 auto;
      &-fields {
        & > div {
          margin-bottom: 40px;
          .post-datepicker {
            border: 1px solid rgba($secondary, 0.2);
            border-radius: $br;
            .MuiInput-underline {
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
    .second-step,
    .third-step {
      label {
        display: block;
        font-family: $font-primary;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: $secondary-light;
        margin-bottom: 8px;
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      font-family: $font-primary;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $secondary;
      margin-bottom: 12px;
      i {
        margin-right: 10px;
        font-size: 30px;
      }
    }
    .step-nav-buttons {
      margin-top: 20px;
      button {
        width: 152px;
        height: 48px;
        line-height: 48px;
        font-weight: bold;
        font-size: 1.125rem;
        &.back-btn {
          color: $secondary;
          margin-right: 20px;
        }
      }
    }
  }
  .bottom-next-step {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #F6F6F6;
    padding: 18px;
    z-index: 9;
    button {
      display: block;
      font-weight: bold;
      font-size: 1.125rem;
      color: #FFFFFF;
      padding: 14px 50px;
      margin: 0 auto;
    }
  }
}