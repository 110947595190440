@media (max-width: $screen-sm) {
  .publish-asset-modal {
    .modal-content {
      .modal-body {
        .left {
          width: calc(100% - 360px);
          padding: 24px;
        }
        .right {
          width: 360px;
        }
      }
    }
  }
  .bid-modal {
    .modal-body {
      .left {
        width: calc(100% - 360px);
      }
      .right {
        width: 360px;
      }
    }
  }
}
@media (max-width: $screen-ssm) {
  .publish-asset-modal {
    .modal-content {
      .modal-head {
        padding: 16px;
      }
      .modal-body {
        display: block;
        .left {
          width: 100%;
          padding: 0;
          .image {
            border-radius: 0;
          }
          .report-btn {
            margin-right: 16px;
          }
        }
        .right {
          width: 100%;
          &-top {
            padding: 16px;
          }
          &-center {
            padding: 22px 16px;
          }
          &-bottom {
            padding: 16px;
          }
        }
      }
    }
  }
  .bid-modal {
    .modal-body {
      display: block;
      .left {
        width: 100%;
        border: none;
        padding: 24px 16px;
      }
      .right {
        width: 100%;
        padding: 60px 16px 24px;
        .time {
          top: 10px;
        }
      }
    }
  }
}
@media (max-width: $screen-xs) {
  .MuiDialog-paper {
    .MuiDialogTitle-root {
      padding: 40px 30px 15px;
      &.with-back-btn {
        .MuiTypography-root {
          padding: 2px 15px;
        }
        .back-btn {
          top: 36px;
          left: 30px;
        }
      }
    }
    .MuiDialogContent-root:not(.no-padd) {
      padding: 0 30px 40px;
    }
  }
  .MuiDialog-container {
    .MuiDialog-paperWidthXs,
    .MuiDialog-paperWidthSm,
    .MuiDialog-paperWidthMd,
    .MuiDialog-paperWidthLg {
      max-width: calc(100% - 32px) !important;
      margin: 16px !important;
    }
  }
  .publish-asset-modal {
    .modal-content {
      .modal-head {
        display: block;
        & > div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .bid-modal-done {
    .modal-body {
      padding: 40px;
    }
  }
  .report-popup-content {
    padding: 24px;
  }
}