.footer {
    margin-top: auto;
    @include bg-img(
        $image: 'footer-img.svg',
        $repeat: no-repeat,
        $size: 507px 386px,
        $color: $bg-grey,
        $position: top left
    );

    &-main {
        padding: 100px 0 65px;
    }
    &-nav-item {
        ul {
            li {
                a {
                    font-family: $font-primary;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.75rem;
                    color: $secondary;
                }
            }
        }
    }
    &-bottom {
        padding: 32px 0;
        background: rgba($secondary, 0.1);
        font-size: 15px;
        color: rgba($secondary, 0.6);
    }

    .copyright {
        margin-left: 12px;
    }
}
