.img-tag {
    position: relative;

    span {
        position: absolute;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.MuiAvatar-root {
    &.sm {
        width: 32px;
        height: 32px;
    }

    &.md {
        width: 40px;
        height: 40px;
    }

    &.xl {
        width: 92px;
        height: 92px;
    }

    &.lg {
        width: 110px;
        height: 110px;
    }

    &.xxl {
        width: 133px;
        height: 133px;
    }

    &.extra {
        width: 170px;
        height: 170px;
    }
}

.logo-with-icon {
    position: relative;
    audio,
    video,
    iframe {
        max-width: 100%;
    }
    span {
        @include position($bottom: 2px, $right: 12px);
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($secondary, 0.3);
    }
}
