@media (max-width: $screen-lg) {
  .home-best-voted {
    padding: 60px 0 120px;
  }
}
@media (max-width: $screen-md) {
  .banner-images {
    .img-tag {
      &.img-1 {
        width: 145px;
      }
      &.img-2 {
        width: 250px;
      }
      &.img-3 {
        width: 120px;
      }
      &.img-4 {
        width: 180px;
      }
    }
  }
  .home-products {
    .home-product {
      width: 180px;
      height: 180px;
      &-icon {
        font-size: 38px !important;
      }
      &-text {
        font-size: 1rem;
      }
    }
  }
  .home-about {
    padding: 180px 0 120px;
    &:after {
      width: 260px;
      height: 260px;
    }
  }
  .home-featured-artists {
    padding: 60px 0 80px;
    &:after {
      display: none;
    }
  }
}
@media (max-width: $screen-sm) {
  .banner {
    padding: 80px 0;
    background-size: 360px 280px;
    &-left {
      width: 100%;
    }
    &-right {
      max-width: 100%;
    }
    &-images {
      .left {
        width: calc(60% - 16px);
      }
    }
  }
  .home-products {
    width: calc(100% + 16px) !important;
    margin: -8px !important;
    .home-product {
      width: 142px;
      height: 142px;
      padding: 8px !important;
      &-arrow {
        padding-top: 4px;
        padding-left: 4px;
        padding-right: 4px;
        svg {
          font-size: 24px !important;
        }
      }
      &-icon {
        font-size: 32px !important;
        margin-bottom: 20px;
      }
    }
  }
  .home-about {
    margin-top: 0;
    padding: 40px 0 120px;
  }
  .home-top-bids {
    padding-bottom: 60px;
  }
  .home-featured-artists {
    padding: 40px 0 60px;
  }
  .home-best-voted {
    padding: 40px 0 80px;
  }
}
@media (max-width: $screen-xs) {
  .banner {
    background-size: 180px 150px;
    padding: 20px 0 80px;
  }
  .home-about {
    padding: 40px 0 60px;
    background-image: none;
    img {
      width: 130px;
    }
    &:after {
      display: none;
    }
  }
  .home-best-voted {
    padding: 40px 0 60px;
  }
}