.thumbnail-slider {
  .slick-slide {
    div {
      div {
        -webkit-border-radius: $br;
        -moz-border-radius: $br;
        border-radius: $br;
        overflow: hidden;
      }
    }
    img {
      width: 100%;
    }
  }
  .preview-mode {
    margin-bottom: 12px;
  }
  .preview-navigation {
    margin-left: -8px;
    margin-right: -8px;
    .slick-slide {
      padding: 8px;
      &.slick-current {
        div {
          div {
            position: relative;
            border: 4px solid #485AFD;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba($secondary, 0.45);
              z-index: 3;
            }
          }
        }
      }
    }
  }
}