@media (max-width: $screen-sm) {
  .settings-content {
    .section {
      padding-left: 24px;
      padding-right: 24px;
    }
    .notif-list {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }
}
@media (max-width: $screen-xs) {
  .settings-content {
    position: relative;
    margin: -80px auto 25px;
    .back-btn {
      margin-bottom: 20px;
    }
    .actions {
      position: fixed;
      width: calc(100% - 32px);
      left: 0;
      bottom: 0;
      margin: 0 16px;
      background: #fff;
      border: 1px solid rgba($secondary, 0.1);
      padding: 16px;
      z-index: 5;
    }
  }
}