.tabs-header {
    display: flex;
    align-items: flex-start;

    &-content {
        flex-grow: 1;

        > div {
            overflow: visible !important;
            padding-bottom: 12px;
        }
    }

    &-left {
        margin-right: 13px;
        margin-top: 9px;
    }

    &-right {
        margin-left: 32px;
        flex-shrink: 0;
    }

    .MuiTabs-flexContainer {
        background-color: #fff;
        outline: 1px solid rgba($secondary, 0.2);
        border-radius: $br;
        overflow: hidden;
    }

    .MuiTab-root {
        min-width: 154px;
    }

    .MuiTab-textColorInherit {
        opacity: 1;
        color: $secondary;
        border-right: 1px solid rgba($secondary, 0.2);

        &.Mui-selected {
            background: $secondary;
            color: #fff;
            border-right: none;
            font-family: $font-secondary;
        }
    }

    .MuiTabs-indicator {
        bottom: 13px;
        background: none;

        &:after {
            content: '';
            @include center(horizontal);
            top: 0;
            width: 0;
            height: 0;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-top: 13px solid $secondary;
        }
    }
}

.tabs-content {
    padding-top: 25px;
}
